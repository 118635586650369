import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';

import { Layout } from 'layout';
import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Image from 'common/Image';

import { PromoPlusProps } from './models';

import './PromoPlus.scss';

const PromoPlus: FC<{ data: PromoPlusProps }> = ({
  data: {
    promoPlus: {
      title,
      banner,
      text: promoText,
      columns,
      baseslegals,
      socialItem,
      legalButton,
      legalText,
    },
  },
}) => (
  <Layout>
    <div className="promoPlus">
      <div>
        {banner && (
          <>
            {banner.image && (
              <Image
                imageData={banner.image.childImageSharp.gatsbyImageData}
                alt={banner.image.altText}
                className="banner__image mb-3 d-none d-md-block"
              />
            )}
            {banner.mobileImage && (
              <Image
                imageData={banner.mobileImage.childImageSharp.gatsbyImageData}
                alt={banner.mobileImage.altText}
                className="banner__image-mobile mb-3 d-block d-md-none"
              />
            )}
            {banner.topText && (
              <div className="mt-2">
                <DangerouslySetInnerHtml
                  html={banner.topText}
                  element="h3"
                  className="banner__top-text"
                />
              </div>
            )}
          </>
        )}
      </div>

      <div className="participation__section">
        <div className="participation__title">
          <DangerouslySetInnerHtml html={title} />
        </div>
        <div className="participation__row row justify-content-center">
          {columns.map(({ text, image }) => (
            <div
              className="col-12 col-md-4 participation__col"
              key={image.childImageSharp.gatsbyImageData.images.fallback.src}
            >
              <div className="participation__grid">
                <Image
                  imageData={image.childImageSharp.gatsbyImageData}
                  alt={image.altText}
                  className="participation__image"
                />
                <DangerouslySetInnerHtml html={text} className="participation__text mt-2" />
              </div>
            </div>
          ))}
        </div>

        <div className="text-center mt-5">
          <DangerouslySetInnerHtml html={promoText} className="" />
          <div className="social-buttons">
            {socialItem?.map(({ icon, link, iconImage }) => (
              <Link
                to={link.url}
                target="_blank"
                data-testid="footer-nav-item"
                rel="noreferrer"
                key={icon}
                className="whatsapp-button"
              >
                <small>
                  {iconImage && (
                    <img
                      src={iconImage.childImageSharp.gatsbyImageData.images.fallback.src}
                      alt={link.name}
                      style={{ width: '25px', height: '25px', marginTop: '-12px' }}
                    />
                  )}
                  <span className="text-social">{icon}</span>
                </small>
              </Link>
            ))}
          </div>
        </div>
      </div>

      <div className="participation__section mb-6 marginB">
        <div className="participation__row row justify-content-center">
          {baseslegals.map(({ text: legalBaseText, image }) => (
            <div
              className="col-12 col-md-4 participation__col mt-3 mb-3"
              key={image.childImageSharp.gatsbyImageData.images.fallback.src}
            >
              <div className="participation__grid">
                <Image
                  imageData={image.childImageSharp.gatsbyImageData}
                  alt={image.altText}
                  className="legalImage"
                />
                <DangerouslySetInnerHtml html={legalBaseText} className="mt-4" />
              </div>
            </div>
          ))}
        </div>
        <div className="text-center">
          {legalButton?.map(({ icon, link, iconImage }) => (
            <Link
              to={link.url}
              target="_blank"
              data-testid="footer-nav-item"
              rel="noreferrer"
              key={icon}
              className=""
            >
              <small className="legal__button">
                {iconImage && (
                  <img
                    src={iconImage.childImageSharp.gatsbyImageData.images.fallback.src}
                    alt={link.name}
                  />
                )}
                <h5 className="mt-2">{icon}</h5>
              </small>
            </Link>
          ))}
        </div>
        {legalText && (
          <div className="legal-text mt-4 text-center">
            <DangerouslySetInnerHtml html={legalText} className="top" />
          </div>
        )}
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  query {
    promoPlus {
      banner {
        topText
        image {
          altText
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
        mobileImage {
          altText
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
      }
      title
      text
      columns {
        text
        image {
          altText
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
      }
      socialItem {
        icon
        iconImage {
          id
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 100)
          }
        }
        link {
          name
          url
        }
      }
      baseslegals {
        text
        image {
          altText
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
      }
      legalButton {
        icon
        iconImage {
          id
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 100)
          }
        }
        link {
          name
          url
        }
        ariaLabel
        cssExtraClass
      }
      legalText
    }
  }
`;

export default PromoPlus;
